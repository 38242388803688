/* Error Fallback Component start ----------------------------- */

.error-fallback-container {
   width: 639px;
   margin: auto;
   background: #f8f8f8 0% 0% no-repeat padding-box;
   box-shadow: 0px 3px 99px #0000001c;
   border-radius: 22px;
}

.error-fallback-container .error-text {
   text-align: center;
}

.error-fallback-container .error-text * {
   margin-bottom: 20px;
   margin-top: 20px;
}

.error-fallback-container .error-text .link-color {
   color: #3b7cff;
}

.error-fallback-container .buttons-container {
   display: flex;
   column-gap: 15px;
   margin-top: 2rem;
}

.error-fallback-container .buttons-container button {
   text-align: center;
   border-radius: 15px;
   position: unset;
}

.error-fallback-container .buttons-container .transparent-button {
   background: white;
   border: 1px solid #000000;
   color: #000000;
}

.error-fallback-container .error-image {
   height: 250px;
   background-image: url('/assets/img/error-component/something-went-wrong.svg');
   background-position: center;
   background-repeat: no-repeat;
}
.error-image.status-400 {
   background-image: url('/assets/img/error-component/400-error.png');
   background-size: contain;
}
.error-image.status-401 {
   background-image: url('/assets/img/error-component/401-error.png');
}
.error-image.status-403 {
   background-image: url('/assets/img/error-component/403-error.png');
}
.error-image.status-404 {
   background-image: url('/assets/img/error-component/404-error.png');
}

.error-fallback-container .main-content {
   padding: 20px;
}

.error-fallback-container .logo-container {
   padding: 10px 0 10px 0;
   margin-left: 20px;
}

.btn {
   background: #ff6600;
   border: none;
   padding: 0.7rem;
   width: 100%;
   min-height: 45px;
   color: white;
   font-weight: 500;
   word-break: break-all;
   font-size: 0.9rem;
   border-radius: 10px;
   cursor: pointer;
   transition: all 0.2s ease;
   /* TODO Remove margin */
   /* margin-top: 2rem; */
}
.btn:hover {
   transition: all 0.2s ease;
   background: #cb5100;
}
.btn:disabled {
   transition: all 0.2s ease;
   background: #959595;
   filter: grayscale(1);
   opacity: 0.5;
   cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
   .error-fallback-container {
      width: 100%;
   }
}

/* Error Fallback Component end ----------------------------- */
