.preloader-container {
   width: 98vw;
   height: 100vh;
   z-index: 9999;
   display: flex;
   align-items: center;
   justify-content: center;
   position: fixed;
   top: 0;
   left: 0;
   background-color: #ffffff;
   overflow: hidden;
}
.preloader-container img {
   width: 200px;
}
